<template>
  <div>
    <div style="height:200px;display:block;position: relative;background-color: rgb(50,50,50);box-shadow: 0 3px 3px rgba(10,10,10,.3)">

      <div style="position:absolute;overflow: hidden;width:100vw;height:100%;opacity: .7">
        <v-img v-if="!isMobile" alt="Main banner" position="center center" height="350" width="auto" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/banners/second_bg.webp" class="mainBanner" />
        <v-img v-if="isMobile" alt="Main banner" height="350" width="auto" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/banners/second_bg.webp" class="mainBannerMobile" />
      </div>

      <div style="position: absolute;width:100%" :style="isMobile ? 'padding-top:100px' : 'padding-top:30px'" class="text-center">
        <v-img :class="isMobile ? 'mt-n3' : ''" class="centerItem" alt="divider image" :width="isMobile ? 300 : 400" height="30" cover lazy-src="@/assets/images/no-image.webp" src="@/assets/images/debesteslagerij.webp" />
        <v-img :class="isMobile ? 'mt-n2' : ''" class="centerItem" alt="divider image" :width="isMobile ? 250 : 350" height="15" cover lazy-src="@/assets/images/no-image.webp" src="@/assets/images/shapes/chalkline.webp" />
        <v-img class="mt-n1" v-if="isMobile" alt="Scouting Karel de Stoute titel" contain width="99%" max-width="400" style="margin-top:-10px;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/titel1.webp" />
        <v-img class="mt-n6" v-if="!isMobile" alt="Scouting Karel de Stoute titel" contain width="800" height="150" style="margin-top:-30px;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/titel1.webp" />
      </div>
    </div>

    <v-row class="mt-6">
      <v-col class="text-center" cols="12" md="6" lg="6" xl="6">
        <v-img alt="site intro" v-if="isMobile" height="300" width="100%" style="max-width:90vw;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/mainpage/team.webp" class="mt-2 mb-4" />
        <v-img alt="site intro" v-if="!isMobile" height="550" width="750" max-width="100%" style="max-width:90vw;margin-left:50%;transform:translate(-50%,0)" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/mainpage/team.webp" class="mt-2 mb-4" />

      </v-col>
      <div style="opacity: 0.001;position: absolute;top:100.1vh;height:1px;color:#ebeef5" v-observe-visibility="infoVisible">yvo mulder</div>

      <transition name="fade">
        <v-col class="mt-4 mb-4" xs="12" sm="12" md="6" lg="6">

          <v-row class="mb-4" style="margin-left:10px;">
            <v-row>
              <div class="ml-2 mr-4" style="width: 90%;margin-right:40px">
                <div class="parent">
                  <v-img style="float:left" lazy-src="@/assets/images/no-image.webp" :src="require('@/assets/images/mini_logo.webp')"  />
                </div>

                <h3 class="ml-4 pt-3 textHeader" v-html="welkom.title"></h3>
                <slot name="custom"></slot>
                <p style="font-family:sans-serif;" class="textHtml" v-html="welkom.text"></p>
              </div>
            </v-row>
          </v-row>

          <v-row class="mb-4" style="margin-left:10px;">
            <v-row>
              <div class="ml-2 mr-4" style="width: 90%;margin-right:40px">
                <div class="parent">
                  <v-img style="float:left" lazy-src="@/assets/images/no-image.webp" :src="require('@/assets/images/mini_logo.webp')"  />
                </div>

                <h3 class="ml-4 pt-3 textHeader" v-html="gezocht.title"></h3>
                <slot name="custom"></slot>
                <p style="font-family:sans-serif;" class="textHtml" v-html="gezocht.text"></p>
              </div>
            </v-row>
          </v-row>

          <v-row class="mb-4" style="margin-left:10px;">
            <v-row>
              <div class="ml-2 mr-4" style="width: 90%;margin-right:40px">
                <div class="parent">
                  <v-img style="float:left" lazy-src="@/assets/images/no-image.webp" :src="require('@/assets/images/mini_logo.webp')"  />
                </div>

                <h3 class="ml-4 pt-3 textHeader" v-html="dinsdag.title"></h3>
                <slot name="custom"></slot>
                <p style="font-family:sans-serif;" class="textHtml" v-html="dinsdag.text"></p>
              </div>
            </v-row>
          </v-row>

          <div v-if="isActiveInfo && isMobile" style="opacity: 0.001!important;height:1px;color:#dedad5" v-observe-visibility="linksVisible">yvo mulder</div>

<!--          <v-row class="mb-4" style="margin-left:10px;">
            <v-row>
              <div class="ml-2 mr-4" style="width: 90%;margin-right:40px">
                <div class="parent">
                  <v-img style="float:left" lazy-src="@/assets/images/no-image.webp" src="@/assets/images/mini_logo.webp" />
                </div>

                <h3 class="ml-4 pt-3 textHeader" v-html="reviews.title + ' ' + reviewScore"></h3>
                <slot name="custom"></slot>
                <p style="font-family:sans-serif;" class="textHtml" v-html="reviews.text"></p>

              </div>
            </v-row>
          </v-row>

          <v-row class="mb-4" style="margin-left:10px;">
            <v-row>
              <div class="ml-2 mr-4" style="width: 90%;margin-right:40px">
                <div class="parent">
                  <v-img style="float:left" lazy-src="@/assets/images/no-image.webp" src="https://www.knsnet.nl/cms/multimedia/000054c8eafbnseq/Logo%20spare%20ribs%20trophy-%20groot.jpg" />
                </div>

                <h3 class="ml-4 pt-3 textHeader" v-html="spareribs.title"></h3>
                <slot name="custom"></slot>
                <p style="font-family:sans-serif;" class="textHtml" v-html="spareribs.text"></p>
              </div>
            </v-row>
          </v-row>-->


          <transition v-if="loaded" name="fade">
            <div v-if="loaded">
              <h3 class="ml-4 pt-3 textHeader">wat vindt u op deze site</h3>
              <page-links folder="links" :items="pageLinks" v-if="loaded" :show_title="true" />
            </div>
          </transition>

        </v-col>
      </transition>


    </v-row>


  </div>
</template>

<script>

import axios from "axios";
axios.defaults.headers.common['ApiKey'] = process.env.VUE_APP_API_KEY;
export default {

  name      : 'Main',
  components: {
    'PageLinks': () => import('@/commons/components/PageLinks'),
  },
  data() {
    return {
      reviewScore:4,
      welkom: {
        title        : 'Welkom',
        introText    : 'Welkom op de website van slagerij traiteur Yvo Mulder.<br/> Op deze website vindt u informatie over onze winkel, het assortiment, buffetten, barbecuepaketten, en fondue-, gourmet.',
        text         :
            'Welkom bij Slagerij Yvo Mulder, de plek waar kwaliteit en smaak hand in hand gaan. Bij ons kunt u terecht voor heerlijke buffetten en gourmetschotels, perfect voor elk feest of gelegenheid. Onze specialiteiten zijn onder andere kipfilet d\'Yvonne, Black Angus en Duroc vleesproducten, waarvan we garanderen dat ze van de hoogste kwaliteit zijn. Ook voor de barbecue liefhebbers onder ons, bieden wij speciale BBQ-pakketten aan. Bovendien kunt u bij ons ook terecht voor uw dagelijkse boodschappen en andere delicatessen. Bestel nu uw buffet of gourmetschotel en ervaar de smaak van onze producten. Tot ziens bij Slagerij Yvo Mulder!'
        ,

      },
      gezocht:{
        title        : 'Slager gezocht',
         text         :
            'Wij zijn op zoek naar een enthousiaste slager die ons team komt versterken. Ben jij een ervaren slager met passie voor het vak en wil je werken in een gezellig team? Dan zijn wij op zoek naar jou! Als slager bij Slagerij Yvo Mulder ben je verantwoordelijk voor het bereiden van vleesproducten, het adviseren van klanten en het schoonhouden van de werkplek. Wij bieden een gezellige werkomgeving, een goed salaris en doorgroeimogelijkheden. Ben jij de slager die wij zoeken? Solliciteer dan nu!'
        ,
      },
      dinsdag:{
        title: 'Dinsdagen gesloten',
        text:'Ivm met personeelstekort zijn wij helaas voorlopig op de dinsdagen gesloten. Onze excuses voor het ongemak.'
      },
      reviews: {
        title        : 'Onze klanten geven ons een',
        introText    : '',
        text         :
            'Wij zijn erg blij met deze score en blijven ons best doen om onze klanten tevreden te houden. Wij zijn erg trots op deze score en willen onze klanten bedanken voor hun vertrouwen in ons.'+
            '\n' +
            'Wilt u ook een review achterlaten? <a style="color:white" href="https://g.page/r/CU3q7GTiOS-1EB0/review">Klik dan hier</>'
        ,

      },
      spareribs: {
        title        : 'Spareribs trophy 2022',
        introText    : '',
        text         :
            'Wist u dat onze spareribs een hele goede prestatie hebben geleverd tijdens de Sparerib Trophy 2022? Dit was de eerste keer dat we meededen aan deze wedstrijd en we zijn blij te melden dat we een score van 9.6 hebben behaald. Dit betekent dat onze spareribs erkend worden als een van de beste in de industrie.\n' +
            '\n' +
            'We blijven ons best doen om onze spareribs van de beste kwaliteit te blijven serveren. Kom snel langs om onze prijswinnende spareribs te bestellen en ervaren waarom ze zo goed beoordeeld zijn.'
        ,

      },
      isActiveInfo : false,
      isActiveLinks: false,
      loaded       : false,
      pageLinks    : [
        {
          title: 'Openingstijden',
          img  : 'schedule',
          url  : '/openingstijden',
        },
        {
          title: 'Assortiment',
          img  : 'restaurant_menu',
          url  : '/assortiment',
        },
        {
          title: 'Bestellen',
          img  : 'shopping_cart',
          url  : '/bestellen',
        },
        {
          title: 'aanbiedingen',
          img  : 'newspaper',
          url  : '/aanbiedingen',
        },

        {
          title: 'Contact',
          img  : 'contact_support',
          url  :false,
          open:'contactForm',
        },

      ],
    };
  },
  methods   : {
    infoVisible(isVisible, entry) {
      isVisible ? this.isActiveInfo = true : '';
    },
    linksVisible(isVisible, entry) {
      isVisible ? this.isActiveLinks = true : '';
    },
  },

  computed: {
    isMobile() {
      return window.innerWidth < 960;
    },

    mainIntro() {
      return window.innerWidth < 960 ? 'siteintromobile.webp' : 'siteintrolarge.webp';
    },

  },

  mounted() {
    if (this.isMobile) {
      setTimeout(() => {
        this.loaded = true;
      }, 3000);
    }
    if (!this.isMobile) {
      this.loaded = true;
    }

    axios.get(process.env.VUE_APP_API_URL + 'reviews').then((response) => {
        this.reviewScore = response.data[0].result.rating;
    });

  },
};
</script>


